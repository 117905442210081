import Swal from "sweetalert2";
import moment from "moment";
import Add from "./Add/Add.vue";

export default {
  name: "AddUser",
  created() {
    this.GetDevices();
    this.GetAllCompanies();
    this.GetAllMadsons();
  },
  components: {
    Add: Add,
  },
  filters: {
    moment: function(date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      mad:[],
      DiseaseId: "",
      countity: "",
      CompanyId: "",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Id:'',
      Devices: [],
      madisons: [],
      citis: [],
      companies: [],
      Municipalities: [],
      SelectedItem: "",
      ruleForm: {
        Id: null,
        Name: "",
        DiseaseId: null,
        ProtocolsDiseases:null
      },
      rules: {
      //  Name: this.$helper.Required(),
      },
    };
  },
  methods: {
    //addTotable() {
    //  let sechem = {
    //    MadisonId: Number(this.DiseaseId),
    //    Quantity: Number(this.countity),
    //  };

    //  let state = 0;
    //  this.ruleForm.ProtocolMadisons.forEach((el) => {
    //    if (el.MadisonId == Number(this.DiseaseId)) {
    //      el.Quantity += Number(this.countity);
    //      state = 1;
    //    }
    //  });
    //  if (state != 1) {
    //    this.ruleForm.ProtocolMadisons.push(sechem);
    //  }
    //},

      Openn() {
          this.ruleForm.Id = null;
          this.ruleForm.Name = null;
          this.ruleForm.Period = null;
          this.ruleForm.DiseaseId = null;
          this.ruleForm.ProtocolsDiseases = [{
              DiseaseId: "",
              Period: ""
          }];
              document.getElementById("open").click();
    },
    GetAllMadsons() {
      this.$blockUI.Start();
      this.$http
        .getMadisons()
        .then((res) => {
          this.$blockUI.Stop();
          this.madisons = res.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
      edit(item) {
          this.ruleForm.Name = item.name;
          this.ruleForm.ProtocolsDiseases = item.protocolsDisease.map(x => {
              return {
                  Id: x.id,
                  DiseaseId: x.diseaseId,
                  Period: x.period,
                  Status : x.status
              }
          })
          this.ruleForm.Id = item.id;

          if (this.ruleForm.ProtocolsDiseases.length == 0) {
              this.ruleForm.ProtocolsDiseases = [{
                  DiseaseId: "",
                  Period: ""
              }];
          }
             document.getElementById("import2").click();
    },

      submitForm(formName) {
          this.$refs[formName].validate((valid) => {
              if (valid) {
                  if (this.ruleForm.Id) {
                       this.update(formName);
                      return true;
                  }
                  this.Add(formName);
                  return true;
              }

              return false;
          });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    update(formName) {
      let schema = this.ruleForm;
      //delete schema.ProtocolMadisons;
      this.$blockUI.Start();
      this.$http
        .editProtocol(schema)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetDevices();
            document.getElementById("close2").click();
          this.resetForm(formName);
          this.ruleForm.Id = null;
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);

        });
    },
    Add(formName) {
      this.$blockUI.Start();
      this.$http
        .AddProtocols(this.ruleForm)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetDevices();
          document.getElementById("close").click();
          this.resetForm(formName);
          this.ruleForm.Id = null;
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);

        });
    },
    AddItemPage() {
      this.state = 1;
    },

    GetAllCompanies() {
      this.$blockUI.Start();
      this.$http
        .GetAllDesiess()
        .then((response) => {
          this.$blockUI.Stop();
          this.companies = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetDevices(pageNo) {
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      this.$http
        .GetProtocols(this.pageNo, this.pageSize)
        .then((response) => {
          this.$blockUI.Stop();
          this.Devices = response.data.info;
          this.pages = response.data.count;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    
    deleteItem45(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deleteElemnt(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.showProtocols(this.Id);
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },

    deleteItem4(Madid) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          const id = (element) => element == Madid;
          let index = this.ruleForm.ProtocolMadisons.findIndex(id);
          this.ruleForm.ProtocolMadisons.splice(index, 1);
        }
      });
    },

    deleteItem(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .DeleteProtocols(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetDevices();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
      },
      AddProtocolsDiseases() {
          this.ruleForm.ProtocolsDiseases.push({
              DiseaseId: null,
              Period: null,
              Status: 1
          })
      },
      RemoveProtocolsDiseases(index, item) {
          if (item.Id)
              item.Status = 9
           else   
             this.ruleForm.ProtocolsDiseases.splice(index, 1)
      },
      setRules() {
          //var customValidate = (rule, value, callback) => {
             
          //    if (!value) {
          //        callback(new Error('الرجاء إختيار المرض'));
          //        return
          //    }
          //    alert(value)
          //        if (this.ruleForm.ProtocolsDiseases.filter(x => x.DiseaseId == value).length > 1)
          //        {
          //            alert(value)
          //            callback(new Error('لا يمكن تكرر المرض في نفس البرتوكول'));
          //            return
          //        }
                      
          //    callback();
          //};
          return {
              Name: [
                  { required: true, message: 'الرجاء إدخال إسم البرتوكول', trigger: 'blur' }
              ],
          }
      }

    // CheckLoginStatus() {
    //     try {
    //         this.loginDetails = JSON.parse(localStorage.getItem('currentUser-client'));
    //         if (this.loginDetails == null) {
    //             window.location.href = '/Login';
    //         }
    //     } catch (error) {
    //         window.location.href = '/Login';
    //     }
    // },
  },
};
