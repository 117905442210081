import editUsers from './AddUsers/AddUsers.vue';
import changePassword from './ChangePassword/ChangePassword.vue';
import Swal from 'sweetalert2'
import moment from 'moment';

export default {
    name: 'AddUser',
    created(){
        this.GetInfo();
        this.GetMunicipalities();
        this.Gitcity();
    },
    components: {
        'edit-Users': editUsers,
        'change-Password': changePassword
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            Municipalities: [],
            MunicipalitId:'',

            Hospitals: [],
            HospitalsId: '',
            KidneyCentersId:'',
            city:[],
            cityId:'',

            Info: [],

            UserType: '',

            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            
            EditUsersObj: [],
        };
    },
    methods: {

        refresh() {

            this.HospitalsId = '';
            this.MunicipalitId = '';
            this.cityId = '';
            this.UserType = '';
            this.GetInfo();
        },

        UserTypeSearch() {
            this.HospitalsId = '';
            this.MunicipalitId='';
            this.GetInfo();
        },

        SelectMunicipalities() {
            this.HospitalsId = null;
            this.GetHospitalsName();
            this.GetInfo();
        },

        GetMunicipalities() {
            this.MunicipalitId = null;
            this.HospitalsId =null;
            this.$blockUI.Start();
            this.$http.GetAllMunicipalities(this.cityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

        Gitcity() {
            this.MunicipalitId = '';
            this.Municipalities = [];
            this.$blockUI.Start();
            this.$http.GetCities()

                .then(response => {
                    this.$blockUI.Stop();

                    this.city = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },


        GetHospitalsName() {
            this.Hospitals = [];
            this.HospitalsId = '';
            this.$blockUI.Start();
            this.$http.GetKednyCenterByCitie(this.MunicipalitId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Hospitals = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

        GetInfo(pageNo) {
            this.Info = [];
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetUsers(this.pageNo, this.pageSize, this.UserType, this.MunicipalitId, this.HospitalsId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },




        GetUsersHospital(pageNo) {

            debugger
            this.Info = [];
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            
            this.$http.GetUsersHospital(this.pageNo, this.pageSize, this.HospitalsId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },


        GetUserPerState(pageNo) {
            this.MunicipalitId = null;
            this.HospitalsId =null;
            this.cityId = null;
            this.Info = [];
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetUsersPerType(this.pageNo, this.pageSize, this.UserType)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },



        DeactivateUser(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من ايقاف تفعيل المستخدم ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusUser(Id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                    showCancelButton: false,
                                //confirmButtonText: `حـفظ`,
                                denyButtonText: `خروج`,
                            }).then(() => {

                            });
                            this.$blockUI.Stop();
                            this.GetInfo(1);
                            // if (this.users.lenght === 1) {
                            //     this.pageNo--;
                            //     if (this.pageNo <= 0) {
                            //         this.pageNo = 1;
                            //     }
                            // }

                            


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الايقاف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;
                }
            })
        },


        ActivateUser(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من  تفعيل المستخدم ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusUser(Id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                    showCancelButton: false,
                                //confirmButtonText: `حـفظ`,
                                denyButtonText: `خروج`,
                            }).then(() => {

                            });
                            this.$blockUI.Stop();
                            this.GetInfo(1);

                            // if (this.users.lenght === 1) {
                            //     this.pageNo--;
                            //     if (this.pageNo <= 0) {
                            //         this.pageNo = 1;
                            //     }
                            // }

                            // this.GetInfo();


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية التفعيل',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;
                }
            })
        },


        delteUser(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteUser(Id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: false,
                                //confirmButtonText: `حـفظ`,
                                denyButtonText: `خروج`,
                            }).then(() => {

                            });
                            this.$blockUI.Stop();
                            this.GetInfo(1);

                            // if (this.users.lenght === 1) {
                            //     this.pageNo--;
                            //     if (this.pageNo <= 0) {
                            //         this.pageNo = 1;
                            //     }
                            // }

                            // this.GetInfo();


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;
                }
            })
        },

        

        EditUser(User) {
            this.state = 2;
            this.EditUsersObj = User;

        },
        Addpage() {
            this.state = 2;
            this.EditUsersObj = null;

        },
        ChangePassword(User) {
            this.state = 3;
            this.EditUsersObj = User;

        }
        



        

        

        


    }
}
