import Swal from "sweetalert2";
import moment from "moment";
import Add from "./Add/Add.vue";

export default {
  name: "AddUser",
  created() {
    this.GetInfo();
    this.GetKidneyCenters();
    this.GetCities();
    //this.GetMunicipalities();

    this.CheckLoginStatus();
  },
  components: {
    Add: Add,
  },
  filters: {
    moment: function(date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
    MunicipalitiesId:"",
    CityId2:"",
    citis:[],
    KidnyCenter:[],
    centerId:"",
      FileNumber: "",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Info: [],
      InfoAttachments: [],
      KidneyCenters: [],

      loginDetails: null,

      Municipalities: [],
      MunicipalitId: "",

      Hospitals: [],
      SelectedHospitalsId: "",

      ViewDilog: false,
      SelectedItem: "",
      Reject: {
        PatientsChangeCenterId: "",
        Resone: "",
      },

      ViewInfoDialog: false,
      AttachmentDilog: false,

      AddViewDilogRequest: false,

      ruleForm: {
        PatientsChangeCenterId: "",
        fileList: [],
        fileBase64: [],
      },
      rules: {
        fileList: [
          {
            required: true,
            message: "الرجاء اختيار  اضافة الملف ",
            trigger: "blur",
          },
        ],
      },

      AddChangeRequestruleForm: {
        FileNumber: "",
        ToCenter: "",
        Note: "",
      },
      AddChangeRequestrules: {
        FileNumber: this.$helper.Required(),
        ToCenter: this.$helper.Required(),
      },
    };
  },
  methods: {
    refresh() {
        this.centerId = "";
        this.MunicipalitiesId = "";
        this.CityId2 = ""
        this.GetInfo();
    },
    GetKednyCenter() {
      this.centerId = null;
        this.$blockUI.Start();
        this.$http
          .GetKednyCenterByCitie(this.MunicipalitiesId)
          .then((response) => {
            this.$blockUI.Stop();
            this.KidnyCenter = response.data.info;
          })
          .catch(() => {
            this.$blockUI.Stop();
          });
      },
    Getmunicipalities() {
      this.centerId=null;

this.MunicipalitiesId = null

        this.$blockUI.Start();
        this.$http
          .GetMunicipalitiesByCiteisID(this.CityId2)
          .then((response) => {
            this.$blockUI.Stop();
            this.Municipalities = response.data.info;
          })
          .catch(() => {
            this.$blockUI.Stop();
          });
      },
    GetCities() {
        this.$blockUI.Start();
        this.$http
          .GetCities()
          .then((response) => {
            this.$blockUI.Stop();
            this.citis = response.data.info;
          })
          .catch(() => {
            this.$blockUI.Stop();
          });
      },
    GetByFileNumber() {
      this.$blockUI.Start();
      return this.$http
        .GetByFileNumberTransfare(Number(this.FileNumber))
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    submitFormAddChangeRequest(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.AddItemChagenRequest(formName);
        } else {
          return false;
        }
      });
    },

    AddItemChagenRequest(formName) {
      this.$blockUI.Start();
      this.$http
        .AddItemChagenRequest(this.AddChangeRequestruleForm)
        .then((response) => {
          this.resetForm(formName);
          this.$blockUI.Stop();
          this.AddViewDilogRequest = false;
          this.AddChangeRequestruleForm.Note = "";
          Swal.fire({
            icon: "success",
            title: "..نجـاح العملية",
            // text: '<strong>Something went wrong!</strong>',
            html: response.data,
            // showCloseButton: true,
            showCancelButton: false,
            //confirmButtonText: `حـفظ`,
            //denyButtonText: `مواق`,
          }).then(() => {
            this.GetInfo(this.pageNo);
          });
        })
        .catch((err) => {
          this.AddViewDilogRequest = false;

          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);
        });
    },

    OpenAddViewDilogRequest() {
      this.AddViewDilogRequest = true;
    },

    ViewAttachmentDilog(item) {
      this.SelectedItem = item;
      this.AttachmentDilog = true;
      this.GetAttachment();
    },

    GetKidneyCenters() {
      this.$blockUI.Start();
      this.$http
        .GetKidneyCentersNames()
        .then((response) => {
          this.$blockUI.Stop();
          this.KidneyCenters = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetAttachment() {
      this.$blockUI.Start();
      this.$http
        .GetChangeAttachments(this.SelectedItem.id)
        .then((response) => {
          this.$blockUI.Stop();
          this.InfoAttachments = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    beforeRemove(file) {
      return this.$confirm(`حذف الملف التالي :  ${file.name} ?`);
    },

    onBeforeUpload(file) {
      const isCorrectFormat =
        file.type === "image/jpg" ||
        "image/png" ||
        ".pdf" ||
        ".docx" ||
        ".xlsx" ||
        ".csv" ||
        ".jpg";

      if (!isCorrectFormat) {
        Swal.fire({
          icon: "warning",
          title: "..الرجاء التاكد من البيانات",
          // text: '<strong>Something went wrong!</strong>',
          html: "يجب ان يكون امتداد الملف png,jpg,docx,xlsx,.csv.pdf",
          // showCloseButton: true,
          showCancelButton: false,
          //confirmButtonText: `حـفظ`,
          //denyButtonText: `مواق`,
        }).then(() => {
          return;
        });
        return;
      }
      return isCorrectFormat;
    },

    handleExceed() {
      this.$message.warning(`لايمكن تحميل أكتر من  ملف `);
    },
    handleRemoveFile(file) {
      var $this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function() {
        $this.ruleForm.fileList.splice(
          $this.ruleForm.fileList.indexOf(reader.result),
          1
        );
      };
    },
    FileChanged(file) {
      var $this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function() {
        var obj = {
          fileName: file.raw.name,
          fileBase64: reader.result,
        };
        $this.ruleForm.fileList.push(obj);
      };
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.AddItem(formName);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.fileList = [];
    },

    AddItem(formName) {
      this.ruleForm.PatientsChangeCenterId = this.SelectedItem.id;
      this.ruleForm.fileBase64 = this.ruleForm.fileList;

      this.$blockUI.Start();
      this.$http
        .AddChangeAttachments(this.ruleForm)
        .then((response) => {
          this.resetForm(formName);
          this.$blockUI.Stop();
          this.AttachmentDilog = false;
          Swal.fire({
            icon: "success",
            title: "..نجـاح العملية",
            // text: '<strong>Something went wrong!</strong>',
            html: response.data,
            // showCloseButton: true,
            showCancelButton: false,
            //confirmButtonText: `حـفظ`,
            //denyButtonText: `مواق`,
          }).then(() => {
            this.GetAttachment();
          });
        })
        .catch((err) => {
          this.AttachmentDilog = false;
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);
        });
    },

    ViewInfo(item) {
      this.SelectedItem = item;
      this.ViewInfoDialog = true;
    },

    RejectApp() {
      this.ViewDilog = false;

      Swal.fire({
        title: "هـل انت متأكد من عملية الرفض ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .RejectChangeRequest(this.Reject)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetInfo(this.pageNo);
                this.Reject.PatientsChangeCenterId = "";
                this.Reject.Resone = "";
                this.SelectedItem = "";
              });
            })
            .catch((err) => {
              this.ViewDilog = false;

              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية الرفض",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data,
              });
            });
          return;
        }
      });
    },

    Accept(id) {
      Swal.fire({
        title: "هـل انت متأكد من العملية  ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .AccepteChangeRequest(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetInfo(this.pageNo);
                this.Reject.PatientsChangeCenterId = "";
                this.Reject.Resone = "";
                this.SelectedItem = "";
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },

    ViewDialogIfno(item) {
      this.ViewDilog = true;
      this.SelectedItem = item;
      this.Reject.PatientsChangeCenterId = item.id;
    },

    CheckLoginStatus() {
      try {
        this.loginDetails = JSON.parse(
          localStorage.getItem("currentUser-client")
        );
        if (this.loginDetails == null) {
          window.location.href = "/Login";
        }
      } catch (error) {
        window.location.href = "/Login";
      }
    },

    GetInfo(pageNo) {
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }

      this.$blockUI.Start();
      this.$http
        .GetCahngeCenter(this.pageNo, this.pageSize)
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
        })
        .catch(() => {
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },

    deleteItem(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .RemoveCahngeAttachments(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetInfo();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },

    deleteItemAttachment(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deleteItemAttachment(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetInfo();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },
  },
};
