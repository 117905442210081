////import Swal from 'sweetalert2'
import moment from 'moment';
import Add from './Add/Add.vue';

export default {
    name: 'AddUser',
    created() {
        // this.GetInfo();
        this.Refresh();
    },
    components: {
        'Add': Add,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10, 
            pages: 0,
            state: 0,
            Info: [],
            value1: '',
            value2: '',
            
        };
    },
    methods: {

        Refresh() {
            this.value2 = '';
            this.value1 = '';
            this.GetInfo();

        },

        Convertmoment(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('YYYY-MM-DDThh:mm:ss.SSS');
            //return moment(date).format('MMMM Do YYYY');
        },

        GetByDate() {
            this.Info = [];
            this.$blockUI.Start();
            this.$http.GetByDate(this.Convertmoment(this.value1), this.Convertmoment(this.value2))
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

        GetInfo() {
            this.$blockUI.Start();
            this.$http.GetUsed()
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },

    }
}
