import Swal from "sweetalert2";
import moment from "moment";
import Add from "./Add/Add.vue";

export default {
  name: "AddUser",
  created() {
    this.GetInfo();
    this.GetPatientNames();
    this.GetCompanyNames();
    this.GetKidneyCenters();

    this.CheckLoginStatus();
    this.GetCities();
  },
  components: {
    Add: Add,
  },
  filters: {
    moment: function(date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      MunicipalitiesId: "",
      CityId2: "",
      citis: [],
      Municipalities: [],

      KidnyCenter: [],
      KidnyCenterId: "",
      FileNumber: "",
      pageNo: 1,
      pageSize: 10,
      pages: 1,
      state: 0,
      loginDetails: "",
      Info: [],
      Patient: [],
      KidneyCenters: [],
      Devices: [],
      Company: [],
      Filter: [],
      ViewInfoDialog: false,
      ruleForm: {
        PatientId: null,
        ProtocolId: null,
        CentersId: null,
        UsedCount: null,
      },
      rules: {
        PatientId: this.$helper.Required(),
        ProtocolId: this.$helper.Required(),
        CentersId: this.$helper.Required(),
        UsedCount: this.$helper.Required(),
      },
    };
  },
  methods: {
    refresh() {
      this.KidnyCenterId = "";
      this.MunicipalitiesId = "";
      this.CityId2 = "";
      this.GetInfo();
    },
    GetKednyCenter() {
      this.KidnyCenterId = null;
      this.$blockUI.Start();
      this.$http
        .GetKednyCenterByCitie(this.MunicipalitiesId)
        .then((response) => {
          this.$blockUI.Stop();
          this.KidnyCenter = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    Getmunicipalities() {
      this.MunicipalitiesId = null;
      this.KidnyCenterId = null;
      this.$blockUI.Start();
      this.$http
        .GetMunicipalitiesByCiteisID(this.CityId2)
        .then((response) => {
          this.$blockUI.Stop();
          this.Municipalities = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    GetCities() {
      this.$blockUI.Start();
      this.$http
        .GetCities()
        .then((response) => {
          this.$blockUI.Stop();
          this.citis = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    GetByFileNumber() {
      //   this.$blockUI.Start();
      //   return this.$http
      //     .GetByFileNumber(Number(this.FileNumber))
      //     .then((response) => {
      //       this.$blockUI.Stop();
      //       this.Info = response.data.info;
      //       this.pages = response.data.count;
      //     })
      //     .catch(() => {
      //       this.$blockUI.Stop();
      //     });
    },
    CheckLoginStatus() {
      try {
        this.loginDetails = JSON.parse(
          localStorage.getItem("currentUser-client")
        );
        if (this.loginDetails == null) {
          window.location.href = "/Login";
        }
      } catch (error) {
        window.location.href = "/Login";
      }
    },

    ViewInfo() {
      this.ViewInfoDialog = true;
    },
    GetKidneyCenters() {
      this.$blockUI.Start();
      this.$http
        .GetKidneyCentersNames()
        .then((response) => {
          this.$blockUI.Stop();
          this.KidneyCenters = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetInfo() {
      this.$blockUI.Start();
      this.$http
        .GetUsedDaily()
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetPatientNames() {
      this.$blockUI.Start();
      this.$http
        .GetPatientNames()
        .then((response) => {
          this.$blockUI.Stop();
          this.Patient = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetDevicesName() {
      this.$blockUI.Start();
      this.$http
        .GetDevicesbyCompany(this.ruleForm.CompanyId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Devices = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetFilterNames() {
      this.$blockUI.Start();
      this.$http
        .GetFilterNames(this.ruleForm.DeviceId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Filter = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetCompanyNames() {
      this.$blockUI.Start();
      this.$http
        .GetAllProtocols()
        .then((response) => {
          this.$blockUI.Stop();
          this.Company = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.AddItem(formName);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    AddItem(formName) {
      this.$blockUI.Start();
      this.ruleForm.UsedCount = Number(this.ruleForm.UsedCount);
      this.$http
        .AddPatientAttendance(this.ruleForm)
        .then((response) => {
          this.resetForm(formName);
          this.$blockUI.Stop();
          this.ViewInfoDialog = false;
          this.ruleForm.CompanyId = "";
          this.ruleForm.DeviceId = "";
          this.ruleForm.FilterId = "";
          this.ruleForm.UsedCount = "";
          // this.ruleForm.KidneyCentersId = "";
          Swal.fire({
            icon: "success",
            title: "..نجـاح العملية",
            // text: '<strong>Something went wrong!</strong>',
            html: response.data,
            // showCloseButton: true,
            showCancelButton: false,
            //confirmButtonText: `حـفظ`,
            //denyButtonText: `مواق`,
          }).then(() => {
            this.GetInfo();
          });
        })
        .catch((err) => {
          this.ViewInfoDialog = false;
          this.$blockUI.Stop();
          this.$helper.showWorning(err.response.data);
        });
    },

    deleteItem(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .DeletePatientAttendance(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetInfo();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },
  },
};
