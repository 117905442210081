import Swal from "sweetalert2";
import moment from "moment";
import Add from "./Add/Add.vue";

export default {
  name: "AddUser",
  created() {
    this.GetFelters();
    this.GetCities();
    this.Getmunicipalities();
    this.GetAllCompanies();
  },
  components: {
    Add: Add,
  },
  filters: {
    moment: function(date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      // DeviceId:"",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Felters: [],
      citis: [],
      Devices: [],
      DevicesTable: [],

      companies: [],
      Municipalities: [],
      SelectedItem: "",
      companiesId: "",
      Kid: "",
      ruleForm2: {
        Id: null,
        // DeviceId: 0,
        Name: "",
      },
      rules2: {
        // DeviceId: this.$helper.Required(),
        Name: this.$helper.Required(),
      },
      ruleForm: {
        Id: null,
        MunicipalityId: "",
        ArabicName: "",
        EnglishName: "",
      },
      rules: {
        MunicipalityId: this.$helper.Required(),
        ArabicName: this.$helper.ArabicOnly(),
        EnglishName: this.$helper.Required(),
      },
    };
  },
  methods: {
    GetDviceById() {
      // this.DeviceId = null
      this.$blockUI.Start();
      this.$http
        .GetDviceById(this.companiesId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Devices = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    GetAllCompanies() {
      this.$blockUI.Start();
      this.$http
        .GetAllCompanies()
        .then((response) => {
          this.$blockUI.Stop();
          this.companies = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    edit(item) {
      this.ruleForm2.Id = item.id;
      this.companiesId = item.company.id;
      this.GetDviceById(this.companiesId);
      // this.ruleForm2.DeviceId = item.devices.id;
      this.ruleForm2.Name = item.name;
      document.getElementById("open").click();
    },

    submitForm2(formName) {

      if (this.$helper.submitForm(this.$refs[formName])) {
        if (this.ruleForm2.Id) {
          return this.update(formName);
        }
        this.Add(formName);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    update(formName) {
      this.$blockUI.Start();
      this.$http
        .UpdateFilters(this.ruleForm2)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetFelters();
          document.getElementById("close2").click();
          this.resetForm(formName);
          this.ruleForm2.Id = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    Add(formName) {
      this.$blockUI.Start();
      this.$http
        .AddFilters(this.ruleForm2)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetFelters();
          document.getElementById("close2").click();
          this.resetForm(formName);
          this.ruleForm2.Id = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    AddItemPage() {
      this.state = 1;
    },
    show(item) {
      this.ruleForm2.FeltersId = item.id;
      this.$blockUI.Start();
      this.$http
        .ShowDevices(item.id)
        .then((res) => {
          this.Kid = item.id;
          this.$blockUI.Stop();
          this.DevicesTable = res.data.info;
          document.getElementById("openDevices").click();
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    deleteDevice(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deleteDeviceKidny(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.show(this.Kid);
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },
    GetCities() {
      this.$blockUI.Start();
      this.$http
        .GetCities(this.pageNo, this.pageSize)
        .then((response) => {
          this.$blockUI.Stop();
          this.citis = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    Getmunicipalities() {
      this.$blockUI.Start();
      this.$http
        .GetAllMunicipalities()
        .then((response) => {
          this.$blockUI.Stop();
          this.Municipalities = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    AddDevice(formName) {
      this.$blockUI.Start();
      this.ruleForm2.Count = Number(this.ruleForm2.Count);
      this.$http
        .AddDeviceToKindycenter(this.ruleForm2)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetFelters();
          document.getElementById("close2").click();
          this.resetForm2(formName);
          this.ruleForm2.FeltersId = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetFelters(pageNo) {
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      this.$http
        .GetFelter(this.pageNo, this.pageSize)
        .then((response) => {
          this.$blockUI.Stop();
          this.Felters = response.data.info;
          this.pages = response.data.count;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deleteFelter(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetFelters();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },

    // CheckLoginStatus() {
    //     try {
    //         this.loginDetails = JSON.parse(localStorage.getItem('currentUser-client'));
    //         if (this.loginDetails == null) {
    //             window.location.href = '/Login';
    //         }
    //     } catch (error) {
    //         window.location.href = '/Login';
    //     }
    // },
  },
};
