import Swal from "sweetalert2";
import moment from "moment";
import Add from "./Add/Add.vue";

export default {
  name: "AddUser",
  created() {
    this.GetDevices();
    // this.GetAllCompanies();
  },
  components: {
    Add: Add,
  },
  filters: {
    moment: function(date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      CompanyId:"",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Devices: [],
      citis: [],
      companies: [],
      Municipalities: [],
      SelectedItem: "",
      ruleForm: {
        Id: null,
        Name: "",
        // DeviceStatusPercentage: Number,
        CompanyId: "",
      },
      rules: {
        Name: this.$helper.Required(),
        // DeviceStatusPercentage: this.$helper.NumberOnly(),
        CompanyId: this.$helper.Required(),
      },
    };
  },
  methods: {
    edit(item) {
      this.ruleForm.Id = item.id;
      this.ruleForm.Name = item.name;
      this.ruleForm.CompanyId = item.company.companyId;
      // this.ruleForm.DeviceStatusPercentage = item.deviceStatusPercentage;
      document.getElementById("open").click();
    },

    submitForm(formName) {
      if (this.$helper.submitForm(this.$refs[formName])) {
        // this.ruleForm.DeviceStatusPercentage = Number(this.ruleForm.DeviceStatusPercentage)
        if (this.ruleForm.Id) {
          return this.update(formName);
        }
        this.Add(formName);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    update(formName) {
      this.$blockUI.Start();
      this.$http
        .UpdateDevices(this.ruleForm)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetDevices();
          document.getElementById("close").click();
        this.resetForm(formName);
this.ruleForm.Id = null;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    Add(formName) {
      this.$blockUI.Start();
      this.$http
        .AddDevices(this.ruleForm)
        .then(() => {
          this.$blockUI.Stop();
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            showCancelButton: false,
          }).then(() => {});
          this.GetDevices();
          document.getElementById("close").click();
        this.resetForm(formName);
this.ruleForm.Id = null;

        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },
    AddItemPage() {
      this.state = 1;
    },

    GetAllCompanies() {
      this.$blockUI.Start();
      this.$http
        .GetAllCompanies()
        .then((response) => {
          this.$blockUI.Stop();
          this.companies = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    GetDevices(pageNo) {
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      this.$http
        .DayUsesDay(this.pageNo, this.pageSize)
        .then((response) => {
          this.$blockUI.Stop();
          this.Devices = response.data.info;
          this.pages = response.data.count;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    deleteItem(id) {
      Swal.fire({
        title: "هـل انت متأكد من عملية الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.$http
            .deleteDevices(id)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
              }).then(() => {
                this.$blockUI.Stop();
                this.GetDevices();
              });
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data);
            });
          return;
        }
      });
    },

    // CheckLoginStatus() {
    //     try {
    //         this.loginDetails = JSON.parse(localStorage.getItem('currentUser-client'));
    //         if (this.loginDetails == null) {
    //             window.location.href = '/Login';
    //         }
    //     } catch (error) {
    //         window.location.href = '/Login';
    //     }
    // },
  },
};
